import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Box, Flex } from "theme-ui";

import { MFV_EVENTS } from "../../analytics/events";
import { Heap } from "../../analytics/heap";
import {
  createUser,
  CreateUserParams,
  GoogleLoginParams,
  googleMFVSignup,
  LoginResult,
  MFVChallengeParams,
} from "../../http/endpoints";
import { Loading, useWaitForLoad } from "../../util/loading";
import ActionBar from "./ActionBar";
import DisclaimerText from "./DisclaimerText";
import ErrorMessage from "./ErrorMessage";
import VerificationCodeInput, {
  VALID_CODE_LENGTH,
} from "./VerificationCodeInput";

const Container = styled(Flex)`
  flex-direction: column;
  gap: 24px;
`;

interface VerifyCodeStepProps {
  mfvChallengeParams?: MFVChallengeParams;
  setResult: (value: Loading<LoginResult>) => void;
  setSubmittedParams?: (params: CreateUserParams) => void;
  signupType?: "Google" | "User/Pass";
  signupParams?: CreateUserParams | GoogleLoginParams;
  onBackClick: () => void;
}

const VerifyCodeStep = ({
  mfvChallengeParams,
  signupParams,
  signupType,
  setResult,
  setSubmittedParams,
  onBackClick,
}: VerifyCodeStepProps) => {
  const [code, setCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isVerifyLoading, setIsVerifyLoading] = useState<boolean>(false);
  const isCodeValid = code?.length === VALID_CODE_LENGTH;

  const waitForLoad = useWaitForLoad();

  const handleSetCode = (code: string) => {
    // This is required because maxLength doesn't work on number inputs
    code = code.slice(0, VALID_CODE_LENGTH);

    if (isCodeValid) {
      setErrorMessage(undefined);
    }
    setCode(code);
  };

  // If backend wants to 2FV this user as well
  const handleSignupPostVerification = useCallback(
    (mfvParams: MFVChallengeParams) => {
      // Required args for user/pass signup
      if (!setSubmittedParams || !createUser) return;
      setResult(Loading.inProgress());
      const params = {
        ...(signupParams as CreateUserParams),
        mfvRequestParams: mfvParams,
      };
      waitForLoad(createUser(params), (result) => {
        if (result.value?.redirectTo) {
          // SSO Redirect
          window.location.href = result.value.redirectTo;
        } else if (result.hasValue) {
          // Signup Success
          setSubmittedParams(params);
        } else {
          // Result is an error
          setErrorMessage(result.error?.message);
        }
        setIsVerifyLoading(false);
        setResult(Loading.unloaded<LoginResult>());
      });
    },
    [setResult, setSubmittedParams, signupParams, waitForLoad],
  );

  // Handles 2FV for google logins
  const handleGooglePostVerification = useCallback(
    (mfvParams: MFVChallengeParams) => {
      setResult(Loading.inProgress());
      waitForLoad(
        googleMFVSignup({
          ...(signupParams as GoogleLoginParams),
          mfvRequestParams: mfvParams,
        }),
        (result) => {
          if (result.hasValue) {
            // Signup Success
            setResult(result);
          } else {
            // Result is an error
            setErrorMessage(result.error?.message);
            setResult(Loading.unloaded<LoginResult>());
          }
          setIsVerifyLoading(false);
        },
      );
    },
    [setResult, signupParams, waitForLoad],
  );

  const handleVerify = useCallback(() => {
    setIsVerifyLoading(true);
    setErrorMessage(undefined);
    Heap.track(MFV_EVENTS.CLICKED_VERIFY_CODE);
    if (mfvChallengeParams && signupParams && signupType) {
      const paramsWithCode = {
        ...mfvChallengeParams,
        verificationCode: code,
      };
      signupType === "Google"
        ? handleGooglePostVerification(paramsWithCode)
        : handleSignupPostVerification(paramsWithCode);
    }
  }, [
    mfvChallengeParams,
    signupParams,
    signupType,
    code,
    handleGooglePostVerification,
    handleSignupPostVerification,
  ]);

  return (
    <Container>
      <Box>
        <VerificationCodeInput
          code={code}
          onSetCode={handleSetCode}
          isError={!!errorMessage}
        />
        <ErrorMessage message={errorMessage} />
        <DisclaimerText />
      </Box>
      <ActionBar
        primaryButtonConfig={{
          onClick: handleVerify,
          isLoading: isVerifyLoading,
          isDisabled: !isCodeValid || isVerifyLoading,
          label: "Verify",
        }}
        secondaryButtonConfig={{
          onClick: onBackClick,
          label: "Back",
        }}
      />
    </Container>
  );
};

export default VerifyCodeStep;
