import { DUIText } from "@alch/ui";
import React from "react";

const MFV_DISCLAIMER_TEXT =
  "Your phone number will be used solely for authentication purposes and will not be subjected to any form of marketing, spam, or unsolicited communication.";

const DisclaimerText = () => {
  return (
    <DUIText size="sm" color="gray.3">
      {MFV_DISCLAIMER_TEXT}
    </DUIText>
  );
};

export default DisclaimerText;
