import { DUIText } from "@alch/ui";
import React from "react";
import styled from "styled-components";
import { Box } from "theme-ui";

const Container = styled(Box)`
  min-height: 50px;
`;

const ErrorText = styled(DUIText)`
  color: red;
  margin-top: 8px;
`;

interface ErrorMessageProps {
  message: string | undefined;
}

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <Container>
      {message && <ErrorText size="sm">{message}</ErrorText>}
    </Container>
  );
};

export default ErrorMessage;
