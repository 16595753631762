import { DUIButton } from "@alch/ui";
import React from "react";
import styled from "styled-components";
import { Flex, Spinner } from "theme-ui";

import { mediaQueries } from "../../styles/mediaQueries";

const ActionBarContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: 8px;
  @media ${mediaQueries.sm} {
    flex-direction: row-reverse;
    align-self: flex-end;
    gap: 16px;
    width: auto;
  }
`;

const ActionButtonContainer = styled(Flex)`
  justify-items: center;
`;

const ActionButton = styled(DUIButton)`
  text-align: center;
  display: inline-block;
`;

interface ActionButtonConfig {
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  label: string;
}

interface ActionBarProps {
  primaryButtonConfig: ActionButtonConfig;
  secondaryButtonConfig: ActionButtonConfig;
}

const ActionBar = ({
  primaryButtonConfig,
  secondaryButtonConfig,
}: ActionBarProps) => {
  return (
    <ActionBarContainer>
      <ActionButton
        onClick={primaryButtonConfig.onClick}
        disabled={primaryButtonConfig.isDisabled}
      >
        <ActionButtonContainer>
          {primaryButtonConfig.isLoading && <Spinner size="20" mr={2} />}
          {primaryButtonConfig.label}
        </ActionButtonContainer>
      </ActionButton>
      <ActionButton
        appearance="outline"
        onClick={secondaryButtonConfig.onClick}
        disabled={secondaryButtonConfig.isDisabled}
      >
        <ActionButtonContainer>
          {secondaryButtonConfig.isLoading && <Spinner size="20" mr={2} />}
          {secondaryButtonConfig.label}
        </ActionButtonContainer>
      </ActionButton>
    </ActionBarContainer>
  );
};

export default ActionBar;
