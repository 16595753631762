/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { memo, ReactElement } from "react";
import { jsx, Link } from "theme-ui";

import { linkSx } from "./styles";

export interface TosProps {
  action: string;
}

const TermsOfService = memo(function TermsOfService({
  action,
  ...otherProps
}: TosProps): ReactElement {
  return (
    <DUIText {...otherProps} size="sm" color="gray.3" mt={4}>
      By clicking “{action}” you are agreeing to Alchemy's{" "}
      <Link
        sx={linkSx}
        href="https://www.alchemy.com/policies/terms"
        target="_blank"
      >
        terms of service
      </Link>{" "}
      and&nbsp;
      <Link
        sx={linkSx}
        href="https://www.alchemy.com/policies/privacy-policy"
        target="_blank"
      >
        privacy policy
      </Link>
      .
    </DUIText>
  );
});
export default TermsOfService;
