import { DUIHeading, DUIText } from "@alch/ui";
import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { Close, Flex } from "theme-ui";

import { useDisableBodyScroll } from "../hooks/useDisableBodyScroll";

// App Element is needed by react-modal for accessibility.
// It hides the app from screen readers when modal is shown.
// For Gatsby apps the app is mounted in the element with the id of `___gatsby`
const appElement =
  typeof window === "undefined"
    ? undefined
    : document.getElementById("___gatsby") ?? undefined;

const InnerContainer = styled(Flex)`
  justify-content: space-between;
`;

const TitleSubtitleContainer = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

const Subtitle = styled(DUIText)`
  text-align: center;
`;

const CloseIcon = styled(Close)`
  color: #64748b;
  cursor: pointer;
  :hover {
    color: #a0aec0;
  }
  width: "24px";
  height: "24px";
`;

interface ModalProps extends ReactModal.Props {
  title: string;
  subtitle?: string;
  onClose: () => void;
}

const Modal = ({ title, subtitle, onClose, isOpen, ...props }: ModalProps) => {
  useDisableBodyScroll(isOpen);

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backdropFilter: "blur(5px)",
        },
        content: {
          inset: "auto",
          borderRadius: "16px",
          maxWidth: "496px",
          margin: "16px",
        },
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={appElement}
      {...props}
    >
      <InnerContainer>
        <TitleSubtitleContainer>
          <DUIHeading size="2xl">{title}</DUIHeading>
          <Subtitle size="sm" color="gray.3">
            {subtitle}
          </Subtitle>
        </TitleSubtitleContainer>
        <CloseIcon onClick={onClose} />
      </InnerContainer>
      {props.children}
    </ReactModal>
  );
};

export default Modal;
