/** @jsx jsx */
import "react-phone-input-2/lib/style.css";

import { memo, useCallback, useEffect, useState } from "react";
import { Box, jsx } from "theme-ui";

import {
  CreateUserParams,
  GoogleLoginParams,
  LoginResult,
  MFVChallengeParams,
} from "../../http/endpoints";
import { Loading } from "../../util/loading";
import Modal from "../Modal";
import VerifyCodeStep from "./VerifyCodeStep";
import VerifyPhoneNumberStep from "./VerifyPhoneNumberStep";

enum MFVModalStep {
  VerifyPhoneNumber,
  VerifyCode,
}
interface MFVModalStepConfig {
  title: string;
  subtitle?: string;
}

const stepConfigs: Record<MFVModalStep, MFVModalStepConfig> = {
  [MFVModalStep.VerifyPhoneNumber]: {
    title: "Verify phone number",
    subtitle: "Enter your phone number to receive a verification code.",
  },
  [MFVModalStep.VerifyCode]: {
    title: "Verify code",
    subtitle: "Please enter the code we provided to ",
  },
};

export interface MultiFactorVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  mfvChallengeParams?: MFVChallengeParams;
  setResult: (value: Loading<LoginResult>) => void;
  setSubmittedParams?: (params: CreateUserParams) => void;
  signupType?: "Google" | "User/Pass";
  signupParams?: CreateUserParams | GoogleLoginParams;
}

const MultiFactorVerificationModal = ({
  isOpen,
  onClose,
  mfvChallengeParams,
  ...props
}: MultiFactorVerificationModalProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState<string>();
  const [activeStep, setActiveStep] = useState<MFVModalStep>(
    MFVModalStep.VerifyPhoneNumber,
  );
  const { title, subtitle } = stepConfigs[activeStep];

  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setPhoneNumber("");
    setFormattedPhoneNumber("");
    setActiveStep(MFVModalStep.VerifyPhoneNumber);
    onClose();
  }, [onClose]);

  const renderActiveStep = useCallback(() => {
    switch (activeStep) {
      case MFVModalStep.VerifyPhoneNumber:
        return (
          <VerifyPhoneNumberStep
            phoneNumber={phoneNumber}
            mfvChallengeParams={mfvChallengeParams}
            onPhoneNumberChange={setPhoneNumber}
            onFormatPhoneNumberChange={setFormattedPhoneNumber}
            onSuccess={() => setActiveStep(MFVModalStep.VerifyCode)}
          />
        );
      case MFVModalStep.VerifyCode:
        return (
          <VerifyCodeStep
            mfvChallengeParams={mfvChallengeParams}
            onBackClick={() => setActiveStep(MFVModalStep.VerifyPhoneNumber)}
            {...props}
          />
        );
    }
  }, [activeStep, mfvChallengeParams, props, phoneNumber]);

  return (
    <Modal
      title={title}
      subtitle={subtitle?.concat(
        activeStep === MFVModalStep.VerifyCode && formattedPhoneNumber
          ? formattedPhoneNumber
          : "",
      )}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <Box mt={6}>{renderActiveStep()}</Box>
    </Modal>
  );
};

export default memo(MultiFactorVerificationModal);
