import { useEffect } from "react";

export const useDisableBodyScroll = (disable: boolean) => {
  useEffect(() => {
    if (disable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [disable]);
};
