import { DUIText } from "@alch/ui";
import React from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import styled from "styled-components";
import { Flex } from "theme-ui";

const Container = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

interface PhoneNumberInputProps {
  number: string | undefined;
  onSetNumber: (
    value: string,
    country: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => void;
  isError?: boolean;
}

const PhoneNumberInput = ({
  number,
  onSetNumber,
  isError,
}: PhoneNumberInputProps) => {
  return (
    <Container>
      <DUIText size="md">Phone number</DUIText>
      <PhoneInput
        inputProps={{
          autoFocus: true,
        }}
        inputStyle={{
          width: "100%",
          fontSize: "16px",
          height: "48px",
          border: `1px solid ${isError ? "red" : "#CBD5E1"}`,
          borderRadius: "8px",
        }}
        buttonStyle={{
          borderBottomLeftRadius: "8px",
          borderTopLeftRadius: "8px",
          backgroundColor: "white",
          borderColor: `${isError ? "red" : "#CBD5E1"}`,
          borderStyle: "solid",
          borderWidth: "1px 0 1px 1px",
        }}
        dropdownStyle={{ position: "fixed", zIndex: 9999, borderRadius: "8px" }}
        country={"us"}
        enableSearch={true}
        disableSearchIcon={true}
        value={number}
        onChange={onSetNumber}
      />
    </Container>
  );
};

export default PhoneNumberInput;
