import React, { useCallback, useState } from "react";
import { CountryData } from "react-phone-input-2";
import styled from "styled-components";
import { Box, Flex } from "theme-ui";

import { MFV_EVENTS } from "../../analytics/events";
import { Heap } from "../../analytics/heap";
import {
  MFVChallengeParams,
  MFVTwilioChannelTypes,
  sendMfvCode,
} from "../../http/endpoints";
import ActionBar from "./ActionBar";
import DisclaimerText from "./DisclaimerText";
import ErrorMessage from "./ErrorMessage";
import PhoneNumberInput from "./PhoneNumberInput";

const E_164_REGEX = /^\+[1-9]\d{6,14}$/;

// This maps to the error string from the backend (https://github.com/OMGWINNING/authchemy/blob/2e76667b87980954cc8d75ef723704cfd58c63e9/packages/authchemy-server/src/util/errorStrings.ts#L15)
const MFV_PHONE_ALREADY_USED_ERROR =
  "This phone number has already been used to verify an existing user. Please try a different phone number.";

const Container = styled(Flex)`
  flex-direction: column;
  gap: 24px;
`;

interface VerifyPhoneNumberStepProps {
  phoneNumber: string | undefined;
  mfvChallengeParams?: MFVChallengeParams;
  onPhoneNumberChange: (phoneNumber: string) => void;
  onFormatPhoneNumberChange: (phoneNumber: string) => void;
  onSuccess?: () => void;
}

const VerifyPhoneNumberStep = ({
  phoneNumber,
  mfvChallengeParams,
  onPhoneNumberChange,
  onFormatPhoneNumberChange,
  onSuccess,
}: VerifyPhoneNumberStepProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isCallCodeLoading, setIsCallCodeLoading] = useState<boolean>(false);
  const [isSmsCodeLoading, setIsSmsCodeLoading] = useState<boolean>(false);
  const isNumberValid = phoneNumber && E_164_REGEX.test(`+${phoneNumber}`);
  const shouldDisableSendCode = !isNumberValid || isCallCodeLoading;

  const handleSendCode = useCallback(
    (mfvChannel: MFVTwilioChannelTypes) => {
      if (!mfvChallengeParams) return;

      setErrorMessage(undefined);
      mfvChallengeParams.userMobileNumber = `+${phoneNumber}` || "";
      mfvChallengeParams.mfvChannel = mfvChannel;

      if (mfvChannel === MFVTwilioChannelTypes.Call) {
        setIsCallCodeLoading(true);
        Heap.track(MFV_EVENTS.CLICKED_CALL_INSTEAD);
      }
      if (mfvChannel === MFVTwilioChannelTypes.Sms) {
        setIsSmsCodeLoading(true);
        Heap.track(MFV_EVENTS.CLICKED_SEND_CODE);
      }
      sendMfvCode(mfvChallengeParams)
        .then(() => {
          setIsCallCodeLoading(false);
          setIsSmsCodeLoading(false);
          onSuccess?.();
        })
        .catch((reason) => {
          setIsCallCodeLoading(false);
          setIsSmsCodeLoading(false);
          setErrorMessage(reason.message);
          if (reason.message === MFV_PHONE_ALREADY_USED_ERROR) {
            Heap.track(MFV_EVENTS.ERROR_PHONE_ALREADY_USED);
          }
        });
    },
    [mfvChallengeParams, onSuccess, phoneNumber],
  );

  const handleSetPhoneNumber = useCallback(
    (
      value: string,
      _country: CountryData,
      _event: React.ChangeEvent<HTMLInputElement>,
      formattedValue: string,
    ): void => {
      if (isNumberValid) {
        setErrorMessage(undefined);
      }

      onPhoneNumberChange(value);
      onFormatPhoneNumberChange(formattedValue);
    },
    [isNumberValid, onFormatPhoneNumberChange, onPhoneNumberChange],
  );

  return (
    <Container>
      <Box>
        <PhoneNumberInput
          number={phoneNumber}
          onSetNumber={handleSetPhoneNumber}
          isError={!!errorMessage}
        />
        <ErrorMessage message={errorMessage} />
        <DisclaimerText />
      </Box>
      <ActionBar
        primaryButtonConfig={{
          onClick: () => handleSendCode(MFVTwilioChannelTypes.Sms),
          isLoading: isSmsCodeLoading,
          isDisabled: shouldDisableSendCode,
          label: "Send Code",
        }}
        secondaryButtonConfig={{
          onClick: () => handleSendCode(MFVTwilioChannelTypes.Call),
          isLoading: isCallCodeLoading,
          isDisabled: shouldDisableSendCode,
          label: "Call instead",
        }}
      />
    </Container>
  );
};

export default VerifyPhoneNumberStep;
