import { LoginResult, MFVChallengeParams } from "../http/endpoints";
import { Loading } from "./loading";

export const getMfvParamsFromResult = (
  result: Loading<Partial<LoginResult>>,
): MFVChallengeParams => {
  return {
    mfvChallengeCode: result.error?.metadata?.mfvChallengeCode,
    email: result.error?.metadata?.email,
  };
};

export const hasMfvChallenge = (
  error: Loading<Partial<LoginResult>>,
): boolean => {
  return error?.error?.metadata?.mfvChallengeCode !== undefined;
};
