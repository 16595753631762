/** @jsx jsx */
import { DUIButtonLink } from "@alch/ui";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import { urlWithQuery } from "../util/queryParams";
import { fullWidthSx } from "./styles";

const SsoSignInButton = (props: {
  action: "Sign in" | "Sign up";
}): ReactElement => {
  const [{ redirectUrl, inviteToken, initialEmail }] = useQueryParams({
    redirectUrl: StringParam,
    inviteToken: StringParam,
    initialEmail: StringParam,
  });

  return (
    <DUIButtonLink
      sx={{ ...fullWidthSx, backgroundColor: "white" }}
      appearance="outline"
      size="lg"
      href={urlWithQuery("/sso-login", {
        redirectUrl,
        inviteToken,
        initialEmail,
      })}
    >
      <FontAwesomeIcon
        icon={faKey}
        sx={{ color: "#555555", mr: 3, mb: "2px" }}
      />
      {props.action} with SSO
    </DUIButtonLink>
  );
};

export default SsoSignInButton;
