import { DUIInput, DUIText } from "@alch/ui";
import React from "react";
import styled from "styled-components";
import { Flex } from "theme-ui";

export const VALID_CODE_LENGTH = 6;

const Container = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`;

const Input = styled(DUIInput)<{ $isError?: boolean }>`
  border-radius: 8px;
  border: ${({ $isError }) => `1px solid ${$isError ? "red" : "#CBD5E1"}`};
`;

interface VerificationCodeInputProps {
  code: string | undefined;
  onSetCode: (code: string) => void;
  isError?: boolean;
}

const VerificationCodeInput = ({
  code,
  onSetCode,
  isError,
}: VerificationCodeInputProps) => {
  return (
    <Container>
      <DUIText size="md">Enter Code</DUIText>
      <Input
        autoFocus={true}
        $isError={isError}
        placeholder="123456"
        type="number"
        required={true}
        value={code}
        onValueChange={onSetCode}
      />
    </Container>
  );
};

export default VerificationCodeInput;
