/** @jsx jsx */
import { DUIButton, DUIButtonProps } from "@alch/ui";
import { useGoogleLogin } from "@react-oauth/google";
import { memo, ReactElement, useCallback } from "react";
import { Image, jsx } from "theme-ui";

import googleIcon from "../images/google-icon.png";
import { fullWidthSx } from "./styles";

export interface GoogleSignInButtonProps extends DUIButtonProps {
  action: "Sign in" | "Sign up";
  onSuccess(code: string): void;
  onFailure?: (error: string) => void;
}

const GoogleSignInButton = memo(function GoogleSignInButton({
  action,
  onSuccess,
  onFailure,
  disabled,
  ...boxProps
}: GoogleSignInButtonProps): ReactElement {
  const handleSuccess = useCallback(
    (res: { code: string }) => {
      onSuccess(res.code);
    },
    [onSuccess],
  );

  const login = useGoogleLogin({
    onSuccess: handleSuccess,
    onError: (err) => {
      onFailure && onFailure(err.error_description || "Google sign in failed.");
      console.error(`Failed to login to Google: ${err}`);
    },
    flow: "auth-code",
  });

  return (
    <DUIButton
      {...boxProps}
      sx={{ ...fullWidthSx, backgroundColor: "white" }}
      appearance="outline"
      size="lg"
      disabled={disabled}
      onClick={() => login()}
    >
      <Image
        sx={{ mr: 3, mb: "2px" }}
        width="20px"
        height="20px"
        src={googleIcon}
      />{" "}
      {action} with Google
    </DUIButton>
  );
});
export default GoogleSignInButton;
