import HCaptcha from "@hcaptcha/react-hcaptcha";
import cookie from "cookie";
import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Box } from "theme-ui";

import { MFV_EVENTS } from "../analytics/events";
import { Heap } from "../analytics/heap";
import { HCAPTCHA_SITE_KEY } from "./HtmlHead";
import Modal from "./Modal";

const DISABLE_HCAPTCHA_COOKIE = "disable-hcaptcha-security";

const HCaptchaModal = ({
  isOpen,
  onClose,
  onVerify,
}: {
  isOpen: boolean;
  onClose: () => void;
  onVerify?: (token: string) => void;
}) => {
  // App Element is needed by react-modal for accessibility.
  // It hides the app from screen readers when modal is shown.
  // For Gatsby apps the app is mounted in the element with the id of `___gatsby`
  const appElement = useMemo(
    () =>
      typeof window === "undefined"
        ? null
        : document.getElementById("___gatsby"),
    [],
  );

  const handleVerify = useCallback(
    (token: string) => {
      Heap.track(MFV_EVENTS.HCAPTCHA_VERIFIED);
      onClose();
      onVerify?.(token);
    },
    [onClose, onVerify],
  );

  useEffect(() => {
    if (!isOpen) return;

    const cookies = cookie.parse(document.cookie);

    if (cookies[DISABLE_HCAPTCHA_COOKIE]) {
      handleVerify("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [isOpen]);

  return (
    <Modal
      title="Verify to continue"
      onClose={onClose}
      isOpen={isOpen}
      appElement={appElement || undefined}
    >
      <Box sx={{ height: 24 }} />
      <HCaptcha
        sitekey={HCAPTCHA_SITE_KEY}
        onVerify={handleVerify}
        onError={(e) => console.error(`Failed to load hCaptcha modal. ${e}`)}
        reCaptchaCompat={false}
      />
    </Modal>
  );
};

export default memo(HCaptchaModal);
